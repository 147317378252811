.side {
  width: 15%;
  text-align: left;
  position: fixed;
  color: rgb(0, 0, 0);
  margin: auto;
  display: block;
  top: 40%;
  transform: translateY(-50%);
  right: 0px;
  padding: 20px;
  background-color: white;
  box-sizing: border-box;
}

.MenuBar p {
  cursor: pointer;
}

.MenuBar p:hover {
  text-decoration: underline;
  color: #464646;
}

.underlined {
  text-decoration: underline;
}

#mobileMenu {
  z-index: 5;
  height: 100vh;
  text-align: left;
}

@media only screen and (max-width:945px){
  .MenuBar {
    width: 100%;
    background-color: white;
  }

  .side {
    display:none;
  }
}

@media only screen and (max-width:480px) and (min-width:100px){
  .MenuBar {
    width: 100%;
    background-color: white;
  }

  .side {
    display:none;
  }
}

@media only screen and (max-width:945px) and (min-width:481px){
  .MenuBar {
    width: 100%;
    background-color: white;
  }

  .side {
    display:none;
  }
}