@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
}

p {
  font-size: 0.8rem;
  text-align: justify;
  text-justify: inter-word;
}

.h1 {
  font-size: 1.3rem;
}

.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@media only screen and (max-width: 390px) and (min-width: 100px){
  p {
    font-size: 0.7rem;
  }

  .h1 {
    font-size: 1rem;
  }

  .h4 {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width:480px) and (min-width: 391px){
  .h1 {
    font-size: 1.2rem;
  }

  .h4 {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width:780px) and (min-width: 481px){
  .h1 {
    font-size: 1.3rem;
  }

  .h4 {
    font-size: 1rem;
  }
}
