.About {
  width: 70vw;
  padding-top: 7vh;
  text-align: left;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  padding-bottom: 15vh;
}

.year {
  padding: 0 20px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  color: #464646;
}

.break {
  width: 10vw;
  border-bottom: 1px solid black;
}

@media only screen and (max-width: 390px) and (min-width: 100px){
  .About {
    width: 90vw;
  }

  .break {
    width: 20vw;
  }
}

@media only screen and (max-width:945px) and (min-width:481px){
  .About {
    width: 80vw;
  }

  .break {
    width: 15vw;
  }
}