.Exhibition {
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  max-width: 70vw;
  padding-top: 15vh;
}

.Exhibition .text {
  text-align: left;
  padding-right: 5vw;
  
}

.Exhibition a {
  text-decoration: none;
}

.Exhibition a:hover{
  color: #464646;
}

.Exhibition a:visited, a:active{
  color: black;
}

.images img {
  max-width: 70vw;
  max-height: 75vh;
  width: auto;
  height: auto;
}
.imageContainer {
  padding-top: 3vh;
  margin-top: 15vh;
  width: auto;
  height: auto;
  line-height: 0vh;
}

.images video  {
  max-width: 70vw;
  max-height: 75vh;
  width: auto;
  height: auto;
}

.imageInfoContainer {
  text-align: left;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  margin-top:5vh;
}

.imageInfoContainer p {
  font-size: 0.7rem;
}

@media only screen and (max-width: 390px) and (min-width: 100px){
  .Exhibition {
    max-width: 90vw;
  }

  .images img {
    max-width: 100%;
  }

  .images {
    max-width: 95vw;
  }

  .text {
    width: 90%;
  }

  .imageInfoContainer {
    max-width: 100%;
    width: 100%;
  }

  .Exhibition a {
    display: none;
  }
}

@media only screen and (max-width:480px) and (min-width: 391px){
  .Exhibition {
    max-width: 90vw;
  }

  .images img {
    max-width: 90vw;
  }

  .images {
    max-width: 90vw;
  }

  .imageInfoContainer {
    max-width: 100%;
    width: 100%;
  }

  .Exhibition a {
    display: none;
  }
}

@media only screen and (max-width:945px) and (min-width: 781px){
  .Exhibition {
    max-width: 80vw;
  }

  .images img {
    max-width: 80vw;
  }

  .images {
    max-width: 80vw;
  }

  .imageInfoContainer {
    max-width: 90vw;
  }
}

@media only screen and (max-width:780px) and (min-width: 481px){
  .Exhibition {
    max-width: 80vw;
  }

  .images img {
    max-width: 80vw;
  }

  .images {
    max-width: 80vw;
  }

  .imageInfoContainer {
    max-width: 90vw;
  }

  .Exhibition a {
    display: none;
  }
}

.video {
  padding: 0 !important;
}

.video-react-control-bar {
  display: none !important;
}

.video-react-button {
  display: none !important;
}