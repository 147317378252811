.MobileMenu {
  width: 70vw;
  padding-top: 7vh;
  text-align: left;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  padding-bottom: 15vh;
}

.MobileMenu p {
  cursor: pointer;
}

.MobileMenu p:hover {
  text-decoration: underline;
  color: #464646;
}

@media only screen and (max-width: 390px) and (min-width: 100px){
  .MobileMenu {
    width: 90vw;
  }
}

@media only screen and (max-width:945px) and (min-width:481px){
  .MobileMenu {
    width: 80vw;
  }
}